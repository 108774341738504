
import { Component, Vue } from 'vue-property-decorator';
import { BugModel } from '@/models/bug';
import { BugList } from '@/collections/bugs';

interface BugOptions {
  filter: {
    status: string;
  };
  sort: {
    field: string;
    order: string;
  };
}

@Component({
  components: {
  },
})
export default class Bugs extends Vue {
  public isLoading: boolean = true;
  public currentPage: number = 1;
  public perPage: number = 50;
  public lastPage: number = 1;
  public bugs: BugList = new BugList();
  public options: BugOptions = {
    filter: {
      status: '',
    },
    sort: {
      field: 'id',
      order: 'desc',
    },
  };

  public created() {
    this.currentPage = Number(this.$route.query.page) || 1;
    this.perPage = Number(this.$route.query.perPage) || 50;
    this.isLoading = true;
    this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.isLoading = true;
    this.bugs = new BugList();
    await this.bugs.fetch(
      this.currentPage,
      this.perPage,
      {
        status: this.options.filter.status,
        orderBy: this.options.sort.field,
        order: this.options.sort.order,
      },
    );
    this.lastPage = this.bugs.lastPage;
    this.isLoading = false;
  }

  public async clearOptions() {
    this.options = {
      filter: {
        status: '',
      },
      sort: {
        field: 'id',
        order: 'desc',
      },
    };
    await this.fetchData();
  }
}
