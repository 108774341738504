import { get } from '@/services/http';
import { BugModel } from '@/models/bug';
import List from './list';

interface BugParams {
  status?: string;
  orderBy?: string;
  order?: string;
}

export class BugList extends List<BugModel> {
  public lastPage!: number;

  public async fetch(
    page: number = 1,
    pageSize: number = 50,
    params: BugParams = {},
  ) {
    const data: any = {
      page,
      perPage: pageSize,
      ...params,
    };
    const res: any = await get(`/bugs`, data);
    const bugs = res.data || [];
    this.lastPage = Number((res.total / pageSize).toFixed(0));
    if (res.total / pageSize > this.lastPage) {
      this.lastPage = this.lastPage + 1;
    }
    for (const s of bugs) {
      const bug: BugModel = new BugModel();
      bug.mapData(s);
      this.add(bug);
    }
  }
}
